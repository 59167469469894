// Copyright © 2021 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { connect } from 'react-redux'

import { selectDeviceModelFirmwareVersions } from '@console/store/selectors/device-repository'

const mapStateToProps = (state, props) => {
  const { brandId, modelId, fwVersion } = props
  const versions = selectDeviceModelFirmwareVersions(state, brandId, modelId)
  const version = versions.find(v => v.version === fwVersion) || { profiles: [] }

  return {
    profiles: Object.keys(version.profiles),
  }
}

export default BandSelect => connect(mapStateToProps)(BandSelect)
